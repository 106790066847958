/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import "../scss/main.scss"
import { appContext } from '../../provider';

const Layout = ({ children, pageTitle, slug, location, noHeaderImage, simpleHeader }) => {
  return (
    <>
      <Header pageTitle={pageTitle} slug={slug} location={location} noHeaderImage={noHeaderImage} simpleHeader={simpleHeader} />
      <div id="page" className="site">
        <main id="content" className="site-content">
          {children}
        </main>
      </div>
      <Footer />
    </>
  )
}


export default Layout
