import React, { useEffect, useRef } from "react"

const InputField = props => {
  const inputRef = useRef()
  useEffect(() => {
    const { current } = inputRef
    const handleFocus = () => {
      current.classList.add("form-control__focused")
    }
    const handleBlur = () => {
      if (current.value.length === 0) {
        current.classList.remove("form-control__focused")
      }
    }

    current.addEventListener("focus", handleFocus)
    current.addEventListener("blur", handleBlur)

    return () => {
      current.removeEventListener("focus", handleFocus)
      current.removeEventListener("blur", handleBlur)
    }
  })

  return (
    <div className={`form-group form-group__custom${props.labelAboveField ? ' form-group__custom--label-above' : '' }`}>
       {props.labelAboveField && (
        <label htmlFor={props.name} className="mb-2 font-weight-bold text-secondary">{props.label}</label>
      )}
      <input
        ref={inputRef}
        type={props.type}
        id={props.name}
        name={props.name}
        className="form-control"
        required={props.required}
        onChange={props.onInputChange}
        {...props}
      />
      {!props.labelAboveField && (
        <label htmlFor={props.name}>{props.label}</label>
      )}
    </div>
  )
}

export default InputField
