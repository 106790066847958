import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useCookies } from "react-cookie"

const InstallPopup = () => {
  const [showModal, setShowModal] = useState(false)
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const [cookies, setCookie] = useCookies(['pwaprompt']);
  const [platform, setPlatform] = useState(null);

  const handleClose = () => {
    setShowModal(false)

    // Set cookie on popup close
    var d = new Date()
    d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000) // 2 days
    setCookie("pwaprompt", "true", { expires: d })
  }

  const handleSetPlatform = () => {
    var userAgent = window.navigator.userAgent.toLowerCase()
    if ( "standalone" in window.navigator && window.navigator.standalone ) {
      setPlatform("standalone")
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      setPlatform("ios")
    } else {
      setPlatform("android")
    }
  }

  const handleNativeAndroidInstallPrompt = () => {
    // console.log(deferredPrompt);
    if (!deferredPrompt) return
    var userAgent = window.navigator.userAgent.toLowerCase()
    deferredPrompt.prompt()
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt")
      } else if (/android/.test(userAgent)) {
        console.log("User dismissed the A2HS prompt")
      }
      setDeferredPrompt(null)
    })
  }

  useEffect(() => {

    if (cookies.pwaprompt) return;

    handleSetPlatform()

    if (
      platform === "ios" &&
      platform !== "standalone"
    ) {
      setShowModal(true)
    }

    // ======================================
    // Android Prompt
    // https://developers.google.com/web/fundamentals/app-install-banners/
    window.addEventListener("beforeinstallprompt", function (e) {
      console.log("beforeinstallprompt")
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault()
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e)

      setShowModal(true)
    })
    
  }, [platform])
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="md"
      className="install-modal"
      aria-labelledby="install-modal-title"
      centered
    >
      <Modal.Header closeButton className="py-0 border-0">
        <div className="modal-title h2 sr-only" id="install-modal-title">
          Install this app
        </div>
      </Modal.Header>
      <div className="">
        <img
          className="m-0"
          src={`prompt-image.jpg`}
          alt=""
        />
        <Modal.Body className="p-3 text-center">
          <h2 style={{lineHeight: "1.2"}}>
            Install the
            <br />
            Peters Product and Rate&nbsp;Finder
          </h2>
          <p>
            Install this application on your home screen for quick and easy
            access when you're on the go.
          </p>
        </Modal.Body>
        <Modal.Footer className="text-center bg-light">
          <div className="w-100">
            {platform === "android" && (
              <button id="android-a2hs" type="button" className="btn btn-primary" onClick={handleNativeAndroidInstallPrompt}>
                + Add to Home screen
              </button>
            )}
            {platform === "ios" && (
              <p id="ios-instructions" className="pwa-instructions m-0">
                Tap{" "}
                <svg
                  fill="#0e7eff"
                  width="30"
                  height="30"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ verticalAlign: 'middle', marginTop: -10 }}
                >
                  <path d="M76.668 35H63.336c-1 0-1.668.668-1.668 1.668s.668 1.668 1.668 1.668h11.668v50h-50v-50l11.664-.004c1 0 1.668-.668 1.668-1.668s-.668-1.668-1.668-1.668L23.332 35c-1 0-1.668.668-1.668 1.668V90c0 1 .668 1.668 1.668 1.668h53.332c1 0 1.668-.668 1.668-1.668V36.668c0-1-.664-1.668-1.664-1.668z"></path>
                  <path d="M38 28l10.332-10.332v45.664C48.332 64.332 49 65 50 65s1.668-.668 1.668-1.668v-46L62 27.664c.668.668 1.668.668 2.332 0 .668-.668.668-1.668 0-2.332L51 12c-.668-.668-1.668-.668-2.332 0L35.336 25.332c-.668.668-.668 1.668 0 2.332.664.668 1.996.668 2.664.336z"></path>
                </svg>{" "}
                then scroll down and <br/>select ‘Add to Home Screen’
              </p>
            )}
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default InstallPopup
