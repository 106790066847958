import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Navbar, Nav, Modal } from "react-bootstrap"
import ICLLogo from "../images/icl_logo.png"

const Header = props => {
  const { menuItems } = props.data.wp.menu
  const { title } = props.data.site.siteMetadata

  const [state, setState] = useState({
    scrolled: props.noHeaderImage ? true : false,
    showModal: false,
    navbarOpacity: props.noHeaderImage ? 1 : 0,
  })

  const handleShow = () =>
    setState({
      ...state,
      showModal: true,
    })
  const handleClose = () =>
    setState({
      ...state,
      showModal: false,
    })

  useEffect(() => {
    const handleScroll = () => {
      setState({
        ...state,
        navbarOpacity: window.scrollY / 100,
      })

      const isScrolled = window.scrollY > 10
      if (isScrolled !== state.scrolled) {
        setState({
          ...state,
          scrolled: !state.scrolled,
        })
      }
    }

    if (!props.noHeaderImage) {
      document.addEventListener("scroll", handleScroll, { passive: true })
      document.dispatchEvent(new Event('scroll'));
    }

    return () => {
      // clean up event listener when component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [state.scrolled, state.navbarOpacity])

  return (
    <header
      id="masthead"
      className="site-header site-header__full"
      data-scrolled={state.scrolled}
      data-top={!state.scrolled}
    >
      <Navbar
        expand="lg"
        fixed="top"
        variant={state.scrolled ? "light text-primary" : "dark text-white"}
        style={{ backgroundColor: `rgba(255,255,255,${state.navbarOpacity})` }}
      >
        <div className="container">
          <Navbar.Brand className="order-2 order-lg-1">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.3 76.9" fill="#0077c5" width="160"><path d="M83.7 75.4c-15.9 0-27.5-10.9-27.5-26s11.6-26 27.5-26c14.2 0 23.3 10.2 23.3 26v5.2H72.3C73.6 59.8 77.7 63 83 63c4.2 0 7.4-1.8 10.3-5.7l.3-.4.4.3 10.5 7.7.4.3-.3.4c-4.8 6-12.8 9.8-20.9 9.8zm-11.5-32H91c-.1-2.1-.9-4.1-2.4-5.6-1.6-1.7-3.9-2.6-6.3-2.6-6.2 0-9.5 4-10.1 8.2zM138.2 75.4c-11.7 0-18.2-5.4-18.2-15.2V37.6h-9.6v-13h9.6V10.2h16v14.4h13.2v13H136v15.7c0 5 .5 8.5 6.6 8.5 2.1 0 4.8-.3 5.8-1.3l.8-.8v13.9l-.3.1c-2.9 1.2-6.3 1.7-10.7 1.7zM181.8 75.4c-15.9 0-27.5-10.9-27.5-26s11.6-26 27.5-26c14.2 0 23.3 10.2 23.3 26v5.2h-34.7c1.3 5.2 5.4 8.4 10.7 8.4 4.2 0 7.4-1.8 10.3-5.7l.3-.4.4.3 10.5 7.7.4.3-.3.4c-4.8 6-12.8 9.8-20.9 9.8zm-11.5-32H189c-.1-2.1-.9-4.1-2.4-5.6-1.6-1.7-3.9-2.6-6.3-2.6-6.1 0-9.4 4-10 8.2zM229.2 74.2h-16V24.6h16v6.8c3.4-5.5 7.6-8 13.7-8 2.2 0 3.9.2 5.1.5l.4.1V38.8l-.6-.2c-2.3-.7-4.3-1-6.3-1-10.4 0-12.3 5.3-12.3 15.8v20.8zM270.6 75.4c-4.8 0-13.7-.9-19.5-6.8l-.3-.3.3-.3 9.1-10.1.4-.4.4.4c2.9 3.5 6.1 5.1 10.2 5.1 1.5 0 6.3-.2 6.3-3 0-2.7-3.7-3.5-8.3-4.4-7.2-1.5-17-3.6-17-15.3s10.5-16.9 20.9-16.9c4.6 0 13 .9 18.4 6.6l.3.4-.3.3-9.2 9.1-.4.4-.3-.4c-2.1-2.6-4.9-3.9-8.3-3.9-1.9 0-5 .7-5 3.5 0 2.4 3.5 3.1 7.9 4.1 7.3 1.6 17.4 3.7 17.4 15.5-.1 15.2-17.6 16.4-23 16.4zM299 0c5.1 0 9.3 4.2 9.3 9.3s-4.2 9.3-9.3 9.3c-5.1 0-9.3-4.2-9.3-9.3S293.9 0 299 0zm0 16.6c3.8 0 6.9-3.3 6.9-7.3S302.8 2 299 2s-6.9 3.3-6.9 7.3 3.1 7.3 6.9 7.3zm-3.6-12.3h3.4c1 0 4.1 0 4.1 3.1 0 2-1.1 2.6-2.3 2.7l2.5 3.9h-2.6l-2.2-3.7h-.3V14h-2.5V4.3h-.1zm2.5 4.1h1c.6 0 1.5 0 1.5-1s-.9-1-1.5-1h-1v2zM0 73.7v.5h16.6V46.6h10.9c12.6 0 20.8-3.8 24.5-11.3L0 40.7v33zM16.6 16.6h7.5c8.8 0 12.9 2.5 12.9 7.8 0 .9-.1 1.8-.3 2.5l17.5-1.8v-.7c0-14.6-9.2-22-27.4-22H0v28.3L16.6 29V16.6z"></path></svg>

              <span className="sr-only">{title}</span>
            </Link>
          </Navbar.Brand>
          <button
            onClick={handleShow}
            type="button"
            aria-label="Toggle navigation"
            className="order-3 menu-toggler d-lg-none"
            data-toggle="modal"
            data-target="#overlay-menu"
          >
            <span className="menu-toggler-icon"></span>
          </button>
          {/* <Navbar.Toggle onClick={handleShow} className="order-3" /> */}

          <Navbar.Collapse id="desktop-nav" className="order-4 order-lg-3">
            <Nav className="ml-auto">
              {menuItems.nodes && (
                <ul className="navbar-nav">
                  {menuItems.nodes.map(link => {
                    var isCurrent =
                      props?.location?.pathname === link.path || false
                    return (
                      <li
                        key={link.databaseId}
                        className={isCurrent ? "nav-item active" : "nav-item"}
                      >
                        <Link
                          to={link.path}
                          activeClassName="active"
                          className="nav-link"
                        >
                          {link.label}
                          {isCurrent && (
                            <span className="sr-only"> (current)</span>
                          )}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="order-1 order-lg-3">
            <Link
              className="search-toggler btn"
              to="/find-a-product/#search"
              style={{ color: "inherit" }}
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path
                  d="M7.2 0a7.2 7.2 0 015.75 11.535l5.145 5.146-1.414 1.414-5.146-5.146A7.2 7.2 0 117.2 0zm0 2a5.2 5.2 0 100 10.4A5.2 5.2 0 007.2 2z"
                  fillRule="evenodd"
                />
              </svg>
              <span className="sr-only">Toggle Search</span>
            </Link>
          </div>
        </div>
      </Navbar>
      <Modal
        show={state.showModal}
        onHide={handleClose}
        size="lg"
        className="main-menu-overlay"
        aria-labelledby="main-menu-title"
      >
        <div className="main-menu-overlay-background">
          <BackgroundImage
            fluid={props.data.file.childImageSharp.fluid}
            backgroundColor={`#fff`}
            style={{ height: "100%" }}
          />
        </div>
        <Modal.Header closeButton>
          <div className="modal-title display-4 text-icl" id="main-menu-title">
            Product Explorer<span className="sr-only"> Navigation</span>
          </div>
        </Modal.Header>
        <div className="modal-custom-content p-3">
          {/* <h2 id="main-menu-title">Product Explorer<span className="sr-only"> Navigation</span></h2> */}
          <ul className="list-unstyled">
            {menuItems.nodes.map(link => {
              var isCurrent = props?.location?.pathname === link.path || false
              return (
                <li key={link.databaseId}>
                  <div className="card h-100">
                    <div className="card-body">
                      <p className="h2 m-0 mt-auto">
                        <Link
                          to={link.path}
                          activeClassName="active"
                          className="stretched-link"
                          onClick={handleClose}
                        >
                          {link.cssClasses && (
                            <i
                              className={
                                link.cssClasses && link.cssClasses.join(" ")
                              }
                              aria-hidden="true"
                            ></i>
                          )}
                          {link.label}
                          {isCurrent && (
                            <span className="sr-only"> (current)</span>
                          )}
                        </Link>
                      </p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          <p className="text-center">
            <img
              src={ICLLogo}
              alt="ICL"
              width="122"
              height="30"
              className="img-fluid mb-0"
            />
          </p>
          <p className="small mb-1 text-center">
            <Link to="/privacy-policy" onClick={handleClose}>Privacy Policy</Link> |{" "}
            <Link to="/terms-and-conditions" onClick={handleClose}>Terms and Conditions</Link>
          </p>
        </div>
      </Modal>
    </header>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          menu(id: "main", idType: NAME) {
            menuItems {
              nodes {
                databaseId
                label
                cssClasses
                order
                path
                target
              }
            }
          }
        }
        file(relativePath: { eq: "default.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 650) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)

// menu_item_image {
//   localFile {
//     childImageSharp {
//       fluid(maxWidth: 700) {
//         ...GatsbyImageSharpFluid_withWebp
//       }
//     }
//   }
// }
